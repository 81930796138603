import { useCallback, useEffect, useState } from "react";
import { fetchApiData } from "../Utils/ApiCalls";
import { COMPANY_DETAILS, DOWNLOAD_PRO_EXPIRED_USERS_LIST } from "../urls";
import useFormStatus from "../Hooks/useFormStatus";
import {
  COMPANY_STATUS,
  COMPANY_TYPE,
  COMPANY_TYPE_NAME,
  RECORDS_PER_PAGE,
} from "../Constants/Constants";
import { Loader } from "./Loader";
import { Alert } from "./Alert";
import usePagination from "../Hooks/usePagination";
import Pagination from "./Pagination";

const CompanyDetails = () => {
  const [companyData, setCompanyData] = useState([]);
  const [companyStatus, setCompanyStatus] = useState({
    key: "ALL",
    value: "All",
  });

  const [searchTxt, setSearchTxt] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();

  const { errorMessage, handleApiResult } = useFormStatus();
  const {
    page,
    setPage,
    totalPages,
    pageNumbers,
    recordsPerPage,
    setRecordsPerPage,
  } = usePagination({ count });

  const fetchCompanyData = useCallback(async () => {
    const params = {
      page: page,
      page_size: recordsPerPage,
    };
    if (searchTxt.length > 0) params.q = searchTxt;
    if (companyStatus.value !== "All") {
      params.status = companyStatus.key;
    }
    setLoading(true);
    const res = await fetchApiData({
      endpoint: COMPANY_DETAILS,
      apiRequest: "GET",
      params: params,
    });
    handleApiResult(res);
    setLoading(false);
    setCompanyData([]);
    if (res?.data?.results) {
      setCompanyData(res?.data?.results);
      const count = res?.data?.count;
      setCount(count);
      // setTotalPages(Math.ceil(count / RECORDS_PER_PAGE));
    }
  }, [searchTxt, companyStatus, page, handleApiResult, recordsPerPage]);

  useEffect(() => {
    fetchCompanyData();
  }, [companyStatus, searchTxt, page, fetchCompanyData]);

  return (
    <div>
      <Alert errorMessage={errorMessage} />
      <div className="px-5 pb-5  align-items-center">
        <h1 className="mt-5 mb-5 text-center text-3xl font-semibold ">
          Company details
        </h1>

        <TableFilters
          searchTxt={searchTxt}
          setSearchTxt={setSearchTxt}
          companyStatus={companyStatus}
          setCompanyStatus={setCompanyStatus}
          setPage={setPage}
          count={count}
        />
        {companyData.length && !loading ? (
          <>
            <ComapnyTable
              companyData={companyData}
              recordsPerPage={RECORDS_PER_PAGE}
              page={page}
              companyStatus={companyStatus}
            />
            <Pagination
              totalPages={totalPages}
              setPage={setPage}
              page={page}
              pageNumbers={pageNumbers}
              setRecordsPerPage={setRecordsPerPage}
              recordsPerPage={recordsPerPage}
            />
          </>
        ) : loading ? (
          <Loader />
        ) : (
          <>No data found</>
        )}
      </div>
    </div>
  );
};

const TableFilters = ({
  searchTxt,
  setSearchTxt,
  companyStatus,
  setCompanyStatus,
  setPage,
  count,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const { handleApiResult, successMessage, errorMessage } = useFormStatus();

  const handleOnClick = async () => {
    setLoading(true);
    const data = {
      start_date: startDate,
      end_date: endDate,
    };
    const res = await fetchApiData({
      endpoint: DOWNLOAD_PRO_EXPIRED_USERS_LIST,
      data: data,
    });
    handleApiResult(res);
    setLoading(false);
    if (res?.data) {
      setStartDate("");
      setEndDate("");
      window.open(res?.data?.url, "_blank");
    }
  };

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <div className="input-group justify-content-between mb-3">
        <div className="col-3 d-flex m-2 p-1">
          <input
            type="text"
            value={searchTxt}
            onChange={(e) => {
              setSearchTxt(e.target.value);
              setPage(1);
            }}
            className="form-control me-1"
            placeholder="Search"
          />
          <button className="btn btn-success">Search</button>
        </div>

        {companyStatus.key === COMPANY_STATUS.PROP_PLAN_EXPIRED.toString() && (
          <div className="d-flex m-2 col-4 p-1 px-2 border border-1 rounded-1">
            <span className="col-3 mt-2 fw-medium text-dark text-opacity-75">
              Churned User List:
            </span>
            <input
              className="form-control"
              type="text"
              placeholder="Start date"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              min="2023-01-01T00:00"
              max="2040-12-31T23:59"
            />
            <div className="mt-2 mx-2">-</div>
            <input
              className="form-control"
              type="text"
              placeholder="End date"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              value={endDate}
              disabled={startDate === ""}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate}
              max="2040-12-31T23:59"
            />
            {loading ? (
              <Loader sm={true} />
            ) : (
              <button
                className="btn btn-success btn-sm mx-1"
                onClick={handleOnClick}
              >
                Download
              </button>
            )}
          </div>
        )}
        <div className="dropdown mx-5 m-2 p-1">
          <span className="fw-medium text-dark text-opacity-75">
            Company Status:{" "}
          </span>
          <button
            className="btn btn-success dropdown-toggle ms-3"
            data-bs-toggle="dropdown"
          >
            {companyStatus?.value}
          </button>
          <ul className="dropdown-menu">
            <li key="All">
              <span
                className="dropdown-item pointer"
                onClick={() => {
                  setCompanyStatus({ key: "ALL", value: "All" });
                  setPage(1);
                }}
              >
                All
              </span>
            </li>
            {Object.entries(COMPANY_TYPE_NAME).map((data) => {
              const key = data[0];
              const val = data[1];
              return (
                <li key={key}>
                  <span
                    className="dropdown-item pointer"
                    onClick={() => {
                      setPage(1);
                      setCompanyStatus({
                        key: key,
                        value: val,
                      });
                    }}
                  >
                    {val}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-2 d-flex flex-row-reverse m-2 mt-3 p-1 ">
          <span className="fw-medium text-dark text-opacity-75">
            {"Total records: " + count}
          </span>
        </div>
      </div>
    </>
  );
};

const ComapnyTable = ({ companyData, recordsPerPage, page, companyStatus }) => {
  const colNames = [
    "#",
    "Name",
    "Company ID",
    "Admin Email",
    "Company Type",
    "Company Status",
    "Pro plan start date",
    "Pro plan end date",
    "Company creation date",
    "Is internal",
    "Is Pilot",
    "Platform access start date",
    "Platform access end date",
  ];

  if (companyStatus.key === COMPANY_STATUS.PROP_PLAN_EXPIRED.toString()) {
    colNames.push("Date of churn");
    colNames.push("Reason for deactivation");
  }

  return (
    <table className="table table-bordered  tab">
      <thead className="text-center">
        <tr>
          {colNames.map((colName, id) => (
            <th scope="col" key={id}>
              {colName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {companyData?.map((data, index) => {
          const companyType = COMPANY_TYPE_NAME[data?.company_status];
          const serialNumber = recordsPerPage * (page - 1) + index + 1;
          const isPlanData = data?.plan;
          const isPlatformAccess = data?.platform_access;
          return (
            <tr className="text-center" key={data?.id}>
              <th>{serialNumber}</th>
              <td>{data?.name}</td>
              <td>{data?.id}</td>
              <td>{data?.admin_email}</td>
              <td>{COMPANY_TYPE[data?.type]}</td>
              <td>{companyType}</td>
              <td>{isPlanData ? isPlanData.start_date?.slice(0, 10) : ""}</td>
              <td>{isPlanData ? isPlanData.end_date?.slice(0, 10) : ""}</td>
              <td>{data?.created_at?.slice(0, 10)}</td>
              <td>{data?.is_internal ? "Yes" : "No"}</td>
              <td>
                {data?.is_pilot === null
                  ? "-"
                  : data?.is_pilot
                  ? "True"
                  : "False"}
              </td>
              <td>
                {isPlatformAccess
                  ? isPlatformAccess.start_date?.slice(0, 10)
                  : ""}
              </td>
              <td>
                {isPlatformAccess
                  ? isPlatformAccess.end_date?.slice(0, 10)
                  : ""}
              </td>
              {companyStatus.key ===
                COMPANY_STATUS.PROP_PLAN_EXPIRED.toString() && (
                <>
                  <td>{data.plan?.unsubscribed_at?.slice(0, 10)}</td>
                  <td>{data.plan?.deactivation_reason}</td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CompanyDetails;
