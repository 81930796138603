import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.8334 8.33434H15L10 13.3343L5.00002 8.33434H9.16669V2.50101H10.8334V8.33434ZM3.33335 15.8343H16.6667V10.001H18.3334V16.6677C18.3334 17.1279 17.9603 17.501 17.5 17.501H2.50002C2.03979 17.501 1.66669 17.1279 1.66669 16.6677V10.001H3.33335V15.8343Z"
        fill="white"
      />
    </svg>
  );
};

export default DownloadIcon;
