import { Tab, Tabs } from "@attentive-platform/stem-ui";
import { useState } from "react";
import { COMPANIES_TAB_ENUM } from "../Constants/Constants";
import CompanyDetails from "./CompanyDetails";
import CompanyUsage from "./CompanyUsage/CompanyUsage";

const Companies = () => {
  const [value, setValue] = useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="flex bg-[#FAFAFA] h-full   justify-center">
      <div
        style={{ height: "calc(100vh - 9rem)" }}
        className="flex flex-col mt-14  bg-white rounded-lg w-11/12  "
      >
        <div className="flex-col bg-white    px-8 items-center  border-gray-50 ">
          {" "}
          <div className="flex justify-center">
            <Tabs
              value={value}
              textColor="secondary"
              indicatorColor="secondary"
              onChange={handleChange1}
            >
              <Tab
                value={COMPANIES_TAB_ENUM.COMPANY_DETAILS}
                label="Company Details"
                className="p-2"
              />
              <Tab
                value={COMPANIES_TAB_ENUM.COMPANY_USAGE}
                label="Company Usage"
                className="p-2"
              />
            </Tabs>
          </div>
          <div className="h-[1px] w-11/12 bg-[#BDBDBD] mb-14"></div>
        </div>

        {value === COMPANIES_TAB_ENUM.COMPANY_DETAILS && <CompanyDetails />}
        {value === COMPANIES_TAB_ENUM.COMPANY_USAGE && <CompanyUsage />}
      </div>
    </div>
  );
};
export default Companies;
